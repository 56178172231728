import React from 'react'

const Home = () => {
    return (
        <div className='home'>
            <h2 className='txt'>POWER BI REPORTS</h2>
        </div>
    )
}

export default Home